.main {
	/* display: flex;
	flex-direction: column;
	gap: 0.45rem;
	align-items: center; */
	min-height: 100vh;
}

.section {
	padding: 5rem 0;
	margin-top: -1px;
}

.first {
	/* background-color: #1B1A1F; */
	/* background-color: #f5e8fc; */
	padding: 3.8rem 0.9rem;
}

.icons-bar {
	display: grid;
	/* grid-template-columns: 1fr; */
	margin: 0 auto;
	gap: 4.7rem;
}

@media screen and (min-width: 768px) {
	.icons-bar {
		/* grid-template-columns: repeat(3, 1fr); */
		gap: 0.9rem;
	}
}

.box {
	/* color: black; */
	color: #f5e8fc;
	text-align: center;
	max-width: 24rem;
	margin: 0 auto;
}




/* == REFACTOR == */


.services {
	display: grid;
	grid-template-columns: 1fr; 
	padding: 0.2rem;
	gap: 0.2rem;
	width: 100%;
	max-width: 2560px;
}

@media (min-width: 768px) { 
	.services {
		grid-template-columns: repeat(3, 1fr);
	}
}