/* SMALL */

.small {
	background-color: hsl(var(--heroui-card));
}

.outer {
	margin: 0 auto;
	padding: 5rem 0.5rem; 
	max-width: 28rem; 
}

.inner {
	width: fit-content;
	margin: 0 auto;
}

.icon {
	margin-bottom: 1.5rem;
	user-select: none;
	height: 3rem;
	width: 3rem;
}

.heading {
	margin-bottom: 1rem;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 700;
	text-align: center;
}

.subheading {
	color: hsl(var(--heroui-foreground) / .7);
	padding-bottom: 0.625rem;
	text-align: center;
}

/* LARGE */

.large {
	background-color: hsl(var(--heroui-card));
	position: relative;
	height: 35rem;
}

.img {
	object-fit: cover;
	height: 100%;
	width: 100%;
	pointer-events: none;
	user-select: none;
	filter: saturate(110%);
}

.fill {
	position: absolute;
	height: 100%;
	width: 100%;
	top: 0;	
}

.gradient {
	background: linear-gradient(0deg, #101010 -10%, #00000000 40%, #00000000 84%, #404040 120%);
}

.header {
	padding: .75rem;
	display: flex;
	flex-direction: column-reverse;
}

.header h2 {
    color: #fff;
	font-weight: 700;
	font-size: 1.5rem;
	line-height: 2rem;	
	text-shadow: 0 1px 0 rgba(0, 0, 0, .6);
}

.header h3 {
    color: #00000066;
	font-weight: 700;
    font-size: .875rem;
    text-transform: uppercase;
    line-height: 1.25rem;
}

.text {
	position: absolute;
	bottom: 0;

	padding: 1rem 1.2rem;
	text-align: center; 
	color: #fff;
	text-shadow: 0 1px 0 rgba(0, 0, 0, .6);
	font-size: 1rem;
}