.header {
    position: relative;
    /* Themes */
    transition: color 300ms ease-in-out;
    background-color: transparent !important;
    /* margin: 2rem 0; */
}

/* @media (prefers-color-scheme: light)  { */
    .header::before { 
        content: "";
        display: table;
        position: absolute;
        width: 100%;
        height: 100%;
        
        /* Themes */
        transition: background 300ms ease-in-out;
        
        background: #fff;
        /* background: radial-gradient(ellipse at 35% 80%, #fff, #eee); */
        
        /* BACKGROUND */
        /* 
        width: calc(100% - 8rem);
        height: calc(100% - 8rem);
        box-sizing: border-box;
        margin: 4rem;
        background: url("./blue_look_right.jpeg") no-repeat center;
        background-size: cover; 
        */

        /* background: #fff;
        background: radial-gradient(ellipse at 35% 80%, #7801ffa9, #0165fc69);  */
        /* opacity: 0;  */
    }
    
/* @media screen and (min-width:764px) {
    .header::before {
        background: #5bdacf;
    }
} */

@media (prefers-color-scheme: dark) {
    .header::before {
        background: #18021f !important;
        background: radial-gradient(ellipse at 35% 80%, #18021f, #14001d);
        /* background: radial-gradient(ellipse at 30% 80%, #d016ffb6, #ff32aaa6); */
        /* background: radial-gradient(ellipse at 35% 80%, #d10cb7, #fa54b5); */
    }
}
    /* .header::before { background: radial-gradient(ellipse at 35% 80%, #ee10d0, #ff52b7); } */
    /* .img { opacity: 0.97;} */

    .img:before {
        content: "";
        display: table;
        width: 0.1px;
        height: 0.1px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-1rem, 0);
        border-radius:50%;
        box-shadow: 20px 2px 50px 50px #00000093,
                    40px 94px 30px 50px #0000008c;
    }
/* } */

@media screen and (min-width: 640px) {
    .textbox {
        text-align: left !important;
        margin-top: 0 !important;
    }

    .btn {
        width: 20rem;
        /* padding: 0; */
    }
}

/* OLD */

.content {
    width: 100%;
    height: inherit;
    margin: 0 auto;

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    padding: 4rem;

    max-width: 1280px;
}

@media only screen and (max-width: 900px) {
    .content {
        grid-template-columns: repeat(3, 1fr);
        padding: 1rem;
    }

    .img {
        display: none;
    }

    .textbox {
        text-align: center;
    }
}

.text {
    position: relative;
    margin: 0 auto;
    height: 100%;
    width: 100%;
    grid-column: span 3;
}

.textbox {
    display: flex;
    flex-direction: column;
    gap: 1.45rem;
    
    width: 100%;
    max-width: 34rem;
    
    position: absolute;
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;

    text-align: left;
    color: hsl(var(--nextui-foreground)/var(--nextui-foreground-opacity,var(--tw-text-opacity)));
}

.img {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    grid-column: span 2;

    left: 2rem;
    padding: 3.5rem 0;
    max-width: 30rem;
    margin: 0 auto;
}